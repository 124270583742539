import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import MovieItem from '../utils/movie-item';
import { OnlyPC, OnlySP } from '../../helper/media-helper';
import LazyImage from '../utils/lazy-image';

const FullItem: React.FunctionComponent<any> = ({ data }) => {
  if (!data.fieldGroupName) return <></>;
  if (data.fieldGroupName.includes('Banner')) {
    if (!data.title && !data.schedule && !data.volume && !data.pickupTitle) {
      return <ProductItem data={data} />;
    } else {
      return <PickupItem data={data} />;
    }
  }

  if (data.fieldGroupName.includes('Movie')) {
    return (
      <MovieItem
        data={{
          id: data.yoututubeid,
          start: Number(data.starttime) || 0,
          end: Number(data.endtime) || 10,
          title: data.title,
          category: data.subtitle,
          imagePC: data.imagePc,
          imageSP: data.imageSp
        }}
      />
    );
  }
};
export default FullItem;

const PickupItem: React.FunctionComponent<any> = ({ data }) => {
  return (
    <div className="index__list-full-item">
      <a href={data.url} target={data.urlTarget ? '_blank' : ''}>
        <figure className="index__list-full-item-figure">
          <OnlyPC>{data.imagePc && <LazyImage className="fit" data={data.imagePc} />}</OnlyPC>
          <OnlySP>{data.imageSp && <LazyImage className="fit" data={data.imageSp} />}</OnlySP>
          <figcaption className="index__list-full-item-figcaption">{data.imageCaption}</figcaption>
        </figure>
      </a>
      <div className="index__list-full-item-meta ff-univ">
        <div className="index__list-full-item-meta-column">
          <p className="index__list-full-item-meta-caption">{data.title}</p>
          <p className="index__list-full-item-meta-volume">{data.volume}</p>
        </div>
        <p className="index__list-full-item-meta-date ff-mono">{data.schedule}</p>
        <div className="index__list-full-item-meta-title">{data.pickupTitle}</div>
        {data.botton !== 'enter' && (
          <a href={data.url} target={data.urlTarget ? '_blank' : ''}>
            <p className="index__list-full-item-meta-more">
              <span>V</span>
              <span>IEW MORE</span>
            </p>
          </a>
        )}
        {data.botton === 'enter' && (
          <div className="index__list-full-item-meta-enter">
            <a href={data.url} target={data.urlTarget ? '_blank' : ''}>
              <OnlyPC>
                <p className="index__list-full-item-meta-more">
                  <span>
                    {' '}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 83 83">
                      <path
                        d="M42.271 56.912a15.571 15.571 0 01-7.952-1.966 13.471 13.471 0 01-5.243-5.473 16.757 16.757 0 01-1.852-7.952A17.47 17.47 0 0128.99 33.6a13.237 13.237 0 015.159-5.557 15.225 15.225 0 017.951-2.025 14.042 14.042 0 017.5 1.91 12.2 12.2 0 014.731 5.415 19.571 19.571 0 011.624 8.294q0 .969-.057 1.653H34.405a8.44 8.44 0 002.422 5.757 7.888 7.888 0 005.5 1.881 7.3 7.3 0 004.161-1.111 5.4 5.4 0 002.223-3.05h7.011a11.408 11.408 0 01-4.674 7.581 15.035 15.035 0 01-8.777 2.564zm6.441-18.582a6.835 6.835 0 00-2.024-4.646 6.591 6.591 0 00-4.588-1.567 7.456 7.456 0 00-4.845 1.567 7.954 7.954 0 00-2.622 4.646z"
                        fill="#fff"
                      />

                      <g opacity=".4">
                        <path
                          d="M41.5 10a31.397 31.397 0 00-22.274 9.226A31.397 31.397 0 0010 41.5a31.398 31.398 0 009.226 22.274A31.397 31.397 0 0041.5 73a31.398 31.398 0 0022.274-9.226A31.398 31.398 0 0073 41.5a31.397 31.397 0 00-9.226-22.274A31.398 31.398 0 0041.5 10m0-2C60.002 8 75 22.998 75 41.5 75 60.002 60.002 75 41.5 75 22.998 75 8 60.002 8 41.5 8 22.998 22.998 8 41.5 8z"
                          fill="#fff"
                        />
                      </g>
                      <g opacity=".2">
                        <path
                          d="M41.5 2a39.37 39.37 0 00-27.93 11.57A39.37 39.37 0 002 41.5a39.37 39.37 0 0011.57 27.93A39.37 39.37 0 0041.5 81a39.37 39.37 0 0027.93-11.57A39.37 39.37 0 0081 41.5a39.37 39.37 0 00-11.57-27.93A39.37 39.37 0 0041.5 2m0-2C64.42 0 83 18.58 83 41.5S64.42 83 41.5 83 0 64.42 0 41.5 18.58 0 41.5 0z"
                          fill="#fff"
                        />
                      </g>
                      <g transform="translate(16 16)" stroke="#fff" strokeWidth="2" opacity=".7">
                        <circle cx="25.5" cy="25.5" r="24.5" fill="none" />
                      </g>
                    </svg>
                  </span>
                  <span>NTER</span>
                </p>
              </OnlyPC>
              <OnlySP>
                <StaticImage src="../../assets/images/index/enter.png" alt="enter" layout={'fullWidth'} />
              </OnlySP>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

const ProductItem: React.FunctionComponent<any> = ({ data }) => {
  return (
    <div className="index__list-full-item">
      <a href={data.url} target={data.urlTarget ? '_blank' : ''}>
        <figure className="index__list-full-item-figure index__list-full-item-figure--pickup">
          <OnlyPC>{data.imagePc && <LazyImage className="fit" data={data.imagePc} />}</OnlyPC>
          <OnlySP>{data.imageSp && <LazyImage className="fit" data={data.imageSp} />}</OnlySP>
          <figcaption className="index__list-full-item-figcaption">{data.imageCaption}</figcaption>
        </figure>
      </a>
    </div>
  );
};
