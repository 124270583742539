import React, { useState, useEffect, useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { OnlyPC, OnlySP } from '../../helper/media-helper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import Util from '../../templates/utils/util';
import { AppContext } from '../../context/context';
import LazyImage from '../utils/lazy-image';

SwiperCore.use([Autoplay]);

const Slide: React.FunctionComponent = () => {
  const context = useContext(AppContext);
  const slideData = useStaticQuery(graphql`
    query homeSlideQuery {
      wp {
        ja: homeConfigGeneral {
          heroBanner {
            optionHeroPc {
              left {
                fontColor
                category
                schedule
                title
                url
                image {
                  sourceUrl
                }
              }
              center {
                fontColor
                category
                schedule
                title
                url
                image {
                  sourceUrl
                }
              }
              right {
                fontColor
                category
                schedule
                title
                url
                image {
                  sourceUrl
                }
              }
            }
            optionHeroSp {
              fontColor
              category
              schedule
              title
              url
              image {
                sourceUrl
              }
            }
          }
        }

        en: homeConfigGeneralEn {
          heroBannerEn {
            optionHeroPcEn {
              left {
                fontColor
                category
                schedule
                title
                url
                image {
                  sourceUrl
                }
              }
              center {
                fontColor
                category
                schedule
                title
                url
                image {
                  sourceUrl
                }
              }
              right {
                fontColor
                category
                schedule
                title
                url
                image {
                  sourceUrl
                }
              }
            }
          }
          heroBannerEn {
            optionHeroSpEn {
              category
              title
              url
              schedule
              fontColor
              image {
                sourceUrl
              }
            }
          }
        }
      }
    }
  `);

  if (!slideData) return;

  const myData = context.locale === 'en_US' ? slideData.wp.en.heroBannerEn : slideData.wp.ja.heroBanner;
  const node = context.locale === 'en_US' ? 'optionHeroPcEn' : 'optionHeroPc';
  const nodeSp = context.locale === 'en_US' ? 'optionHeroSpEn' : 'optionHeroSp';

  return (
    <div className="index__slide">
      <div className="index__slide-a">
        <div className="only-pc">
          <SlideElem isFirst={true} data={myData[node]} param={'left'} />
          <SlideElem isFirst={false} data={myData[node]} param={'center'} />
          <SlideElem isFirst={false} data={myData[node]} param={'right'} />
        </div>
        <div className="only-sp">
          <SPSlideElem data={myData[nodeSp]} />
        </div>
      </div>
    </div>
  );
};
export default Slide;

const interleaveOffset = -0.5;
const SlideElem: React.FunctionComponent<any> = ({ data, isFirst, param }) => {
  const [mySwiper, setSwiper] = useState(null);

  const onChange = (): void => {
    if (mySwiper && !mySwiper.destroyed) {
      mySwiper.slideNext();
    }
  };

  useEffect(() => {
    if (!isFirst && mySwiper) {
      Util.Dispatcher.addEventListener('INDEX_SLIDE_CHANGE', onChange);
    }
  }, [mySwiper]);

  useEffect(() => {
    return () => {
      setSwiper(null);
      Util.Dispatcher.removeEventListener('INDEX_SLIDE_CHANGE', onChange);
    };
  }, []);

  return (
    <div className="index__slide-a-slide">
      <Swiper
        speed={550}
        watchSlidesProgress={true}
        autoplay={
          isFirst
            ? {
                disableOnInteraction: false,
                delay: 5000
              }
            : false
        }
        allowTouchMove={false}
        loop={true}
        onInit={(swiper) => {
          if (!isFirst) {
            setSwiper(swiper);
          }
        }}
        onSlideChange={() => {
          if (isFirst) Util.Dispatcher.dispatchEvent('INDEX_SLIDE_CHANGE');
        }}
        onProgress={(swiper) => {
          for (let i = 0; i < swiper.slides.length; i++) {
            const slide = swiper.slides[i];
            let translate;
            let innerTranslate;
            const progress: number = slide.progress;

            if (progress > 0) {
              translate = progress * swiper.width;
              innerTranslate = translate * interleaveOffset;
            } else {
              innerTranslate = Math.abs(progress * swiper.width) * interleaveOffset;
              translate = 0;
            }
            swiper.slides[i].style.transform = `translate3d(${translate}px,0,0)`;
            swiper.slides[i].querySelector('.slide-inner').style.transform = 'translate3d(' + innerTranslate + 'px,0,0)';
          }
        }}
        onTouchStart={(swiper) => {
          for (var i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].removeAttribute('transform');
          }
        }}
        onSetTransition={(swiper, speed) => {
          for (var i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = speed + 'ms';
            swiper.slides[i].querySelector('.slide-inner').style.transition = speed + 'ms';
          }
        }}>
        {data &&
          data.map((elem: any, i: number) => {
            if (elem[param].image && elem[param].image.sourceUrl) {
              return (
                <SwiperSlide key={`index-slide-elem-${param}-${i}`}>
                  {elem[param].url && (
                    <a className="slide-inner" href={elem[param].url}>
                      <LazyImage rootMargin={'100%'} data={{ mediaItemUrl: elem[param].image.sourceUrl }} className="fit" />
                      <SlideAMeta color={elem[param].fontColor} category={elem[param].category} date={elem[param].schedule}>
                        {elem[param].title}
                      </SlideAMeta>
                    </a>
                  )}
                  {!elem[param].url && (
                    <span className="slide-inner">
                      <LazyImage rootMargin={'100%'} data={{ mediaItemUrl: elem[param].image.sourceUrl }} className="fit" />
                      <SlideAMeta color={elem[param].fontColor} category={elem[param].category} date={elem[param].schedule}>
                        {elem[param].title}
                      </SlideAMeta>
                    </span>
                  )}
                </SwiperSlide>
              );
            } else {
              return (
                <SwiperSlide key={`index-slide-elem-${param}-${i}`}>
                  {elem[param].url && (
                    <a className="slide-inner" href={elem[param].url}>
                      <SlideAMeta color={elem[param].fontColor} category={elem[param].category} date={elem[param].schedule}>
                        {elem[param].title}
                      </SlideAMeta>
                    </a>
                  )}
                  {!elem[param].url && (
                    <span className="slide-inner">
                      <SlideAMeta color={elem[param].fontColor} category={elem[param].category} date={elem[param].schedule}>
                        {elem[param].title}
                      </SlideAMeta>
                    </span>
                  )}
                </SwiperSlide>
              );
            }
          })}
      </Swiper>
    </div>
  );
};

/* =================================================================== */
/* =================================================================== */
/* =================================================================== */

const SlideAMeta: React.FunctionComponent<any> = (props) => {
  return (
    <div className="index__slide-a-meta ff-univ" style={{ color: props.color }}>
      <p className="index__slide-a-meta-category">{props.category}</p>
      <p className="index__slide-a-meta-title ff-univ-bold">{props.children}</p>
      <div className="index__slide-a-meta-date">{props.date}</div>
    </div>
  );
};

const SPSlideElem: React.FunctionComponent<any> = ({ data }) => {
  return (
    <div className="index__slide-a-slide">
      <Swiper
        speed={550}
        watchSlidesProgress={true}
        autoplay={{
          disableOnInteraction: false,
          delay: 5000
        }}
        loop={true}
        onProgress={(swiper) => {
          for (let i = 0; i < swiper.slides.length; i++) {
            const slide = swiper.slides[i];
            let translate;
            let innerTranslate;
            const progress: number = slide.progress;

            if (progress > 0) {
              translate = progress * swiper.width;
              innerTranslate = translate * interleaveOffset;
            } else {
              innerTranslate = Math.abs(progress * swiper.width) * interleaveOffset;
              translate = 0;
            }
            swiper.slides[i].style.transform = `translate3d(${translate}px,0,0)`;
            swiper.slides[i].querySelector('.slide-inner').style.transform = 'translate3d(' + innerTranslate + 'px,0,0)';
          }
        }}
        onTouchStart={(swiper) => {
          for (var i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].removeAttribute('transform');
          }
        }}
        onSetTransition={(swiper, speed) => {
          for (var i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = speed + 'ms';
            swiper.slides[i].querySelector('.slide-inner').style.transition = speed + 'ms';
          }
        }}>
        {data &&
          data.map((elem: any, i: number) => {
            if (elem.image && elem.image.sourceUrl) {
              return (
                <SwiperSlide key={`index-slide-elem-${i}`}>
                  {elem.url && (
                    <a className="slide-inner" href={elem.url}>
                      <LazyImage rootMargin={'100%'} data={{ mediaItemUrl: elem.image.sourceUrl }} className="fit" />
                      <SlideAMeta color={elem.fontColor} category={elem.category} date={elem.schedule}>
                        {elem.title}
                      </SlideAMeta>
                    </a>
                  )}
                  {!elem.url && (
                    <span className="slide-inner">
                      <LazyImage rootMargin={'100%'} data={{ mediaItemUrl: elem.image.sourceUrl }} className="fit" />
                      <SlideAMeta color={elem.fontColor} category={elem.category} date={elem.schedule}>
                        {elem.title}
                      </SlideAMeta>
                    </span>
                  )}
                </SwiperSlide>
              );
            } else {
              return (
                <SwiperSlide key={`index-slide-elem-${i}`}>
                  {elem.url && (
                    <a className="slide-inner" href={elem.url}>
                      <SlideAMeta color={elem.fontColor} category={elem.category} date={elem.schedule}>
                        {elem.title}
                      </SlideAMeta>
                    </a>
                  )}
                  {!elem.url && (
                    <span className="slide-inner">
                      <SlideAMeta color={elem.fontColor} category={elem.category} date={elem.schedule}>
                        {elem.title}
                      </SlideAMeta>
                    </span>
                  )}
                </SwiperSlide>
              );
            }
          })}
      </Swiper>
    </div>
  );
};
