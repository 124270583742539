import React, { useEffect, useMemo, useState } from 'react';
import { graphql } from 'gatsby';

import Slide from '../components/index/slide';
import Category from '../components/index/category';
import List from '../components/index/list';
import '../css/index.css';
import { AppContext } from '../context/context';
import DefaultMeta from '../components/utils/default-meta';

// markup
const IndexPage = ({ data, pageContext }) => {
  const context = React.useContext(AppContext);

  React.useEffect(() => {
    context.setLanguage(
      {
        ja: '/',
        en: '/en/'
      },
      pageContext.locale
    );
  }, []);

  const createCategoryData = (): any => {
    return {
      ja: data.pickup.ja ? data.pickup.ja.pickup.optionPickup : null,
      en: data.pickup.en ? data.pickup.en.pickupEn.optionPickupEn : null
    };
  };
  const categoryData: any = useMemo(() => createCategoryData(), []);

  return (
    <div className="index util__page">
      <DefaultMeta />
      <Slide />
      <Category data={pageContext.categoryData} ja={categoryData.ja} en={categoryData.en} />
      {context.locale && (
        <List data={data.journal.edges} wide={data.wide} free={data.free} pickup={categoryData} key={`index-list-${context.locale}`} />
      )}
    </div>
  );
};

export default IndexPage;

export const query = graphql`
  query homeQuery($locale: String!) {
    journal: allWpGqlJournalItem(filter: { locale: { locale: { eq: $locale } } }, sort: { fields: date, order: DESC }) {
      edges {
        node {
          databaseId
          date
          gqlJournalCategories {
            nodes {
              name
            }
          }
          optionJournalFields {
            title
            thumbnail {
              mediaItemUrl
              mediaDetails {
                height
                width
              }
            }
          }
        }
      }
    }
    pickup: wp {
      ja: homeConfigGeneral {
        pickup {
          optionPickup {
            ... on WpGqlJournalItem {
              databaseId
              date
              gqlJournalCategories {
                nodes {
                  name
                }
              }
              optionJournalFields {
                title
                image {
                  src {
                    mediaItemUrl
                    mediaDetails {
                      height
                      width
                    }
                  }
                }
              }
            }
          }
        }
      }
      en: homeConfigGeneralEn {
        pickupEn {
          optionPickupEn {
            ... on WpGqlJournalItem {
              databaseId
              date
              gqlJournalCategories {
                nodes {
                  name
                }
              }
              optionJournalFields {
                title
                image {
                  src {
                    mediaItemUrl
                    mediaDetails {
                      height
                      width
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    free: wp {
      ja: homeConfigGeneral {
        optionFreespace {
          optionFreespace {
            first {
              url
              urlTarget
              pc {
                mediaItemUrl
                mediaDetails {
                  width
                  height
                }
              }
              sp {
                mediaItemUrl
                mediaDetails {
                  width
                  height
                }
              }
            }
            second {
              url
              urlTarget
              pc {
                mediaItemUrl
                mediaDetails {
                  width
                  height
                }
              }
              sp {
                mediaItemUrl
                mediaDetails {
                  width
                  height
                }
              }
            }
          }
        }
      }
      en: homeConfigGeneralEn {
        optionFreespaceEn {
          optionFreespaceEn {
            first {
              url
              urlTarget
              pc {
                mediaItemUrl
                mediaDetails {
                  width
                  height
                }
              }
              sp {
                mediaItemUrl
                mediaDetails {
                  width
                  height
                }
              }
            }
            second {
              url
              urlTarget
              pc {
                mediaItemUrl
                mediaDetails {
                  width
                  height
                }
              }
              sp {
                mediaItemUrl
                mediaDetails {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }

    wide: wp {
      ja: homeConfigGeneral {
        widePickup {
          optionWidePickups {
            ... on WpHomeConfigGeneral_Widepickup_OptionWidePickups_BlockBanner {
              botton
              fieldGroupName
              imageCaption
              pickupTitle
              schedule
              title
              url
              urlTarget
              volume
              imagePc {
                mediaItemUrl
                mediaDetails {
                  height
                  width
                }
              }
              imageSp {
                mediaItemUrl
                mediaDetails {
                  height
                  width
                }
              }
            }
            ... on WpHomeConfigGeneral_Widepickup_OptionWidePickups_BlockMovie {
              endtime
              fieldGroupName
              yoututubeid
              title
              subtitle
              starttime
              imagePc {
                mediaItemUrl
              }
              imageSp {
                mediaItemUrl
              }
            }
          }
        }
      }
      en: homeConfigGeneralEn {
        widePickupEn {
          optionWidePickupsEn {
            ... on WpHomeConfigGeneralEn_Widepickupen_OptionWidePickupsEn_BlockBanner {
              botton
              fieldGroupName
              imageCaption
              pickupTitle
              schedule
              title
              url
              urlTarget
              volume
              imagePc {
                mediaItemUrl
                mediaDetails {
                  height
                  width
                }
              }
              imageSp {
                mediaItemUrl
                mediaDetails {
                  height
                  width
                }
              }
            }
            ... on WpHomeConfigGeneralEn_Widepickupen_OptionWidePickupsEn_BlockMovie {
              endtime
              fieldGroupName
              yoututubeid
              title
              subtitle
              starttime
              imagePc {
                mediaItemUrl
              }
              imageSp {
                mediaItemUrl
              }
            }
          }
        }
      }
    }
  }
`;
