import React, { useContext } from 'react';
import { OnlyPC, OnlySP } from '../helper/media-helper';
import { Link } from 'gatsby';
import { PATH } from '../helper/config';
import Util from '../templates/utils/util';

import JournalHover from './utils/journal-hover';
import LazyImage from './utils/lazy-image';
import { AppContext } from '../context/context';

const ListItem: React.FunctionComponent<any> = ({ data, isLarge }) => {
  const context = useContext(AppContext);
  let localePath: string = '';
  if (context.locale === 'en_US') localePath = '/en';

  if (data.free) {
    const freeURL: string = data.free.url ? data.free.url : '';
    let link_target: string = data.free.urlTarget ? '_blank' : '_top';

    return (
      <a
        href={freeURL}
        className={`util__list-item util__list-free-item ${isLarge ? 'large' : ''}`}
        style={{
          pointerEvents: freeURL.length ? 'all' : 'none'
        }}
        target={link_target}>
        <figure className="util__list-free-item-figure">
          <OnlyPC>
            {((): any => {
              if (data.free.pc.mediaItemUrl.includes('.mp4')) {
                return (
                  <video
                    src={data.free.pc.mediaItemUrl}
                    width={data.free.pc.mediaDetails.width}
                    height={data.free.pc.mediaDetails.height}
                    muted
                    playsInline
                    autoPlay
                    loop></video>
                );
              } else {
                return <LazyImage data={data.free.pc} />;
              }
            })()}
          </OnlyPC>
          <OnlySP>
            {((): any => {
              if (data.free.sp.mediaItemUrl.includes('.mp4')) {
                return (
                  <video
                    src={data.free.sp.mediaItemUrl}
                    width={data.free.sp.mediaDetails.width}
                    height={data.free.sp.mediaDetails.height}
                    muted
                    playsInline
                    autoPlay
                    loop></video>
                );
              } else {
                return <LazyImage data={data.free.sp} />;
              }
            })()}
          </OnlySP>
        </figure>
      </a>
    );
  }

  return (
    <Link to={`${localePath}${PATH.JOURNAL_DETAIL}${data.node.databaseId}`} className={`util__list-item ${isLarge ? 'large' : ''}`}>
      <div className="util__list-item-wrap">
        <div className="util__list-item-inner">
          <OnlyPC>
            <p className="util__list-item-date ff-mono">{Util.GET_DATE(data.node.date).ja}</p>
          </OnlyPC>
          <figure className="util__list-item-figure">
            {data.node.optionJournalFields.thumbnail && <LazyImage className="fit" data={data.node.optionJournalFields.thumbnail} />}
          </figure>
          <OnlySP>
            <p className="util__list-item-date ff-mono">{Util.GET_DATE(data.node.date).ja}</p>
          </OnlySP>
          <p className="util__list-item-title">{data.node.optionJournalFields.title}</p>

          {data.node.gqlJournalCategories && data.node.gqlJournalCategories.nodes && data.node.gqlJournalCategories.nodes[0] && (
            <p className="util__list-item-category ff-mono">#{data.node.gqlJournalCategories.nodes[0].name}</p>
          )}
        </div>
      </div>
      <OnlyPC>
        <JournalHover data={data.node} />
      </OnlyPC>
    </Link>
  );
};
export default React.memo(ListItem);
