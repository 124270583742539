import React, { useContext, useEffect, useState } from 'react';

import FullItem from './full-item';
import ListItem from '../../components/list-item';
import { OnlyPC } from '../../helper/media-helper';
import { AppContext } from '../../context/context';

const perPage: number = 15;

const List: React.FunctionComponent<any> = ({ data, wide, free, pickup }) => {
  const context = useContext(AppContext);

  const targetPickUp = context.locale === 'en_US' ? pickup.en : pickup.ja;
  const targetWide = context.locale === 'en_US' ? wide.en.widePickupEn.optionWidePickupsEn : wide.ja.widePickup.optionWidePickups;
  const targetFree = context.locale === 'en_US' ? free.en.optionFreespaceEn.optionFreespaceEn : free.ja.optionFreespace.optionFreespace;

  const [wideSlide, setWide] = useState([...targetWide]);
  const [max, setMax] = useState(perPage);
  const [showData, setShowData] = useState([]);
  const [freeCount, setFreeCount] = useState(-1);
  const [freeSecondCount, setFreeSecondCount] = useState(-1);

  let largeCount: number = 0;

  useEffect(() => {
    let tempData = [];
    data.map((elem) => {
      if (targetPickUp) {
        if (targetPickUp.databaseId !== elem.node.databaseId) {
          tempData.push(elem);
        }
      } else {
        tempData.push(elem);
      }
    });

    let isFree = freeCount;
    if (isFree === -1) {
      isFree = Math.round(Math.random() * Math.min(tempData.length - 1, 14));
      setFreeCount(isFree);
    }

    let isSecondFree = freeSecondCount;
    if (isSecondFree === -1 && tempData.length > 15) {
      isSecondFree = Math.round(15 + Math.random() * Math.min(tempData.length - 1, 15));
      setFreeSecondCount(isSecondFree);
    }

    if (targetFree && targetFree.first.pc) {
      tempData.splice(isFree, 0, {
        free: targetFree.first
      });
    }

    if (targetFree && targetFree.second.pc) {
      tempData.splice(isSecondFree, 0, {
        free: targetFree.second
      });
    }

    let listMax = max;
    if (isFree) listMax++;
    if (isSecondFree) listMax += 2;

    setShowData(tempData.slice(0, listMax));
  }, [max]);

  const more = (): void => {
    setMax(Math.min(max + perPage, data.length - 1));
  };

  return (
    <>
      {showData.map((elem: any, i: number) => {
        let isLoop = false;
        if (i % 12 === 0 && i > 0) isLoop = true;

        if (i % 6 === 0) {
          return (
            <React.Fragment key={`index__list-item-${i}`}>
              <div className={`index__list ${isLoop ? 'index__list--loop' : ''}`}>
                {showData.map((listElem: any, j: number) => {
                  if (j >= i && j <= i + 5) return <ListItem data={listElem} key={`index__list-small-item-${i}-${j}`} />;
                })}
              </div>
              {((): any => {
                if (wideSlide && wideSlide[largeCount]) {
                  return (
                    <React.Fragment key={`index__list-large-item-${i}`}>
                      <FullItem data={wideSlide[largeCount]} />
                      {((): any => {
                        largeCount++;
                      })()}
                    </React.Fragment>
                  );
                }
              })()}
            </React.Fragment>
          );
        }
      })}

      {max < data.length - 1 && (
        <div
          className="index__list-more "
          onClick={() => {
            more();
          }}>
          <p className="index__list-more-content ff-univ-bold">
            <span>LOAD</span>
            <img className="default" src="/load_more.gif" alt="" />
            <OnlyPC>
              <img className="hover" src="/load_more2.gif" alt="" />
            </OnlyPC>
            <span>MORE</span>
          </p>
        </div>
      )}
    </>
  );
};
export default List;
