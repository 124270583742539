import React from 'react';
import dayjs from 'dayjs';

const JournalHover: React.FunctionComponent<any> = ({ data }) => {
  return (
    <div className="util__journal-hover ff-univ">
      <span className="util__journal-hover-top">POST FAKE JOURNAL</span>
      <span className="util__journal-hover-left">
        <span>{dayjs(data.date).format('MMMM DD, YYYY @ HH:MM')}</span>
      </span>
      {data.gqlJournalCategories.nodes.length && (
        <span className="util__journal-hover-right">#{data.gqlJournalCategories.nodes[0].name}</span>
      )}
      <span className="util__journal-hover-bottom">
        Read this article
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="13" viewBox="0 0 80 13">
          <path fill="#fff" d="M80 6.5L59 13V0z" />
          <path fill="#fff" d="M51 6.5L29 13V0z" />
          <path fill="#fff" d="M21 6.5L0 13V0z" />
        </svg>
      </span>
    </div>
  );
};
export default JournalHover;
